<template>
  <div
    :id="id"
    class="phpMap"
  />
</template>

<script>
import ida from '@/utils/ImportDynamicAssets';
import { DEFAULT_COORDINATES } from '@/constants/map.const';
import { normalizeCoordinates } from '@/utils/mapsHelpers';
import EventHandlerMixin from '@/mixins/EventHandler';

import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';

const mapStyles = ida.json('/map-style.json');

export default {

  mixins: [EventHandlerMixin],

  props: {
    manager: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
      default: () => ({
        lat: DEFAULT_COORDINATES.latitude,
        lng: DEFAULT_COORDINATES.longitude,
      }),
    },
  },

  data() {
    return {
      id: `map-${parseInt(Math.random() * 1000, 10)}`,
    };
  },

  computed: {
    mapManager() {
      return this.manager;
    },
  },

  mounted() {
    const google = googleMapsLoader.getInstance();

    const { latitude, longitude } = normalizeCoordinates(this.location.lat, this.location.lng);

    const mapOptions = {
      zoom: this.mapManager.getZoomLevelToShowCityMarkers(),
      center: new google.maps.LatLng(latitude, longitude),
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      styles: mapStyles.styles,
    };

    this.mapManager.setMap(new google.maps.Map(document.getElementById(this.id), mapOptions));

    google.maps.event.addListenerOnce(this.mapManager.getMap(), 'tilesloaded', () => {
      this.mapManager.mapIsLoaded = true;
      this.onMapLoaded();
    });

    this.mapManager.getMap().addListener('bounds_changed', () => {
      if (this.mapManager.mapIsLoaded) {
        this.onBouncing();
      }
    });

    this.mapManager.getMap().addListener('zoom_changed', () => {
      if (this.mapManager.mapIsLoaded) {
        this.onZoomChanging();
      }
    });
  },

  methods: {
    onBouncing() {
      if (!this.mapManager.isMapboundChanging()) {
        this.mapManager.toggleMapbound(true);
        this.eventHandler.$emit(this.events.MAP_BOUNCING);
        this.mapManager.toggleMapbound(false);
      }
    },

    onZoomChanging() {
      if (!this.mapManager.isZoomMapChanging()) {
        this.mapManager.toggleZoomMap(true);
        this.eventHandler.$emit(this.events.ZOOM_MAP_CHANGING);
        this.mapManager.toggleZoomMap(false);
      }
    },

    onMapLoaded() {
      if (this.mapManager.mapIsLoaded) {
        this.eventHandler.$emit(this.events.MAP_LOADED, this.mapInstance);
        this.$emit('ready:map', this.mapInstance);
      }
    },
  },

};

</script>
